import { navigate, PageProps } from 'gatsby';
import { Form } from 'packages/formidable';
import { EspaceData, UserData } from 'packages/innedit';
import React, { FC } from 'react';

import params from '~/params/espace/create.json';
import requireUser, { UserProps } from '~/utils/requireUser';

const EspaceCreatePage: FC<PageProps & UserProps> = function ({ user }) {
  const handleOnSubmit = async (values: any) => {
    const espaceData = new EspaceData({});
    const espace = await espaceData.create(values);
    // On enregistre l'espace comme l'espace par défaut de l'utilisateur
    const userData = new UserData();
    userData.update(user.id, {
      espaceIds: [...(user.espaceIds ?? []), espace.id],
    });

    navigate(`/espaces/${espace.id}/dashboard`);
  };

  return (
    <div>
      <div className="flex min-h-main flex-col items-center justify-center">
        <div className="mx-auto w-full max-w-md">
          <Form
            bodyProps={{
              className: 'flex flex-col space-y-3 mb-12',
            }}
            datas={params}
            enableReinitialize
            footerProps={{
              className: 'mt-6 flex justify-center',
            }}
            name="user"
            onSubmit={handleOnSubmit}
            submitProps={{
              className: 'w-full',
              label: 'Créer un espace de travail',
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default requireUser(EspaceCreatePage);
